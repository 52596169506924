<template>
  <metainfo>
    <!-- <template v-slot:description="{ metainfo }">{{ metainfo.description }}</template> -->
  </metainfo>
  <div class="home">
    <section class="mx-5 px-3">
      <div>
        <!-- IDE animation component -->
        <!-- <splash /> -->
      </div>
    </section>
    <section class="py-4" id="splash">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h1 class="font-weight-bold text-center">
              The Rwanda Build Program
              <b> (RWbuild) </b>
            </h1>
            <h4 class="font-weight-bold text-center">
              A Tech Hub & Startup Studio in Kigali, Rwanda
            </h4>
          </div>
        </div>
        <div class="row" id="about" ref="splash_ref">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="banner_image" v-if="!isBannerPlaying">
              <img :src="require(`@/assets/images/banners/ban_${0}.png`)" alt />
              <button class="play_btn" @click="playBanner()">
                <span class="fa fa-play"></span>
              </button>
            </div>
            <div class="banner-slide mt-4">
              <div class="slide-item" v-for="i in 14" :key="i + 'dd'" :data-image="i">
                <div class="slide-image fadeIn">
                  <img :src="require(`@/assets/images/banners/ban_${i}.png`)" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <code-summit-details/> -->
    <!-- RWBuild Vision -->
    <section class="py-4 mb-3 px-md-5 px-0 dark">
      <div class="container-fluid">
        <div>
          <div class="row align-items-center">
            <div class="col-lg-5 text-center">
              <img data-aos="fade-right" data-aos-easing="linear" data-aos-duration="3000"
                src="@/assets/images/vision-image.png" width="400" height="200" class="img-thumbnail rounded"
                alt="about-us" />
            </div>
            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="3000"
              class="col-lg-7 text-lg-left text-center mission-text">
              <h4 class="font-weight-bold my-2" style="color: rgba(245, 245, 245, 0.872)">
                RWBuild Tech Hub & Startup Studio
              </h4>
              <p class="mission">
                The Rwanda Build Program (RWBuild) is a tech hub and startup
                studio that supports aspiring entrepreneurs in building software
                companies from the ground up. The program offers a range of
                resources and expertise to help founders and their teams
                throughout the ideation and launch process, with the goal of
                creating successful and sustainable startups that will be
                venture-ready.
              </p>
              <a href="/about-us">
                <button type="button" class="btn btn-primary primary mt-3" @click="goToAboutUs">
                  About Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of RWBuild Vision -->
    <!-- Our project section -->
    <section class="fadeInUp recently-project mx-md-5 mx-sm-4 px-3 pt-3 mb-lg-0 mb-md-5 mb-sm-5" id="current">
      <div class="widget" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" data-aos-easing="ease-in-out"
        data-aos-mirror="false" data-aos-once="false">
        <div class="content">
          <h4 class="heading font-bold current-project">
            Our Current Projects
          </h4>
          <div class="d-flex row">
            <div class="d-flex col-lg-4 col-md-6 col-sm-10" v-for="(project, index) in projects" :key="index + 'djdjs'"
              style="width: 100% !important">
              <!-- <div class="d-flex project-widget"> -->
              <div class="card" style="width: 100% !important">
                <div class="project-img">
                  <img class="img-responsive animated-right" :src="require(`@/assets/images/project/${project.img_name}`)
                    " alt="project" />
                </div>

                <div>
                  <h5 v-if="project.link" class="summit-link">
                    <a :href="project.link" v-html="project.title" target="_blank"></a>
                  </h5>
                  <h5 v-else>
                    <a :href="project.link" v-html="project.title" target="_blank" class="summit-no-link"></a>
                  </h5>
                  <p class="projects" v-html="project.content"></p>
                  <span class="projects" v-if="project.fblink || project.tweeterlink">
                    Follow us:
                    <a v-if="project.fblink" :href="project.fblink" target="_blank"
                      style="text-decoration: none; color: black">Facebook ,</a>
                    <a v-if="project.tweeterlink" :href="project.tweeterlink" target="_blank"
                      style="text-decoration: none; color: black">
                      Tweeter</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
    <!--Current 2024 Event -->
    <section class="py-4 my-4 px-md-5 px-0 dark" ref="vision_ref">
      <div class="container-fluid">
        <div>
          <div class="row align-items-center">
            <div class="col-lg-5 text-center">
              <img data-aos="fade-right" data-aos-easing="linear" data-aos-duration="3000"
                src="@/assets/images/startup_founders_talk_brang_logo.png" width="500" height="200"
                class="img-thumbnail rounded" />
            </div>
            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="3000"
              class="col-lg-7 text-lg-left text-center mission-text"
              style="color: rgba(245, 245, 245, 0.872); height: 200px">
              <h4 class="font-weight-bold my-2" style="color: rgba(245, 245, 245, 0.872)">
                Current 2024 Event
              </h4>
              <p class="mission">
                RWBuild has events often,Follow us on Twitter for updated
                current event schedules<br />and check
                <a href="https://eventsbash.rw/" target="_blank" class="event-link">EventsBash.rw </a>for a complete
                events listing of what is happening in Kigali.
                <br />
                <a href="https://twitter.com/RwandaBuild" target="_blank">
                  <button type="button" class="btn btn-primary primary mt-4">
                    Follow us on Twitter
                  </button></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- current events , Worshops & Trainings section -->
    <section class="fadeInUp mx-md-4 px-md-4 mx-1 px-1 mb-2 pt-4" id="summit" ref="summit_ref">
      <div class="container-fluid">
        <div class="widget">
          <h4 class="heading font-bold">Past Workshop & Training</h4>
          <div class="row" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" data-aos-easing="ease-in-out"
            data-aos-mirror="false" data-aos-once="false" style="margin-left: -15px; margin-top: -30px !important">
            <div class="d-flex col-lg-4 col-md-6 col-sm-6" v-for="(summit, index) in summits" :key="index">
              <div class="d-flex project-widge" style="margin-top: 40px">
                <div class="card summit-card">
                  <div>
                    <img class="card-img-top" :src="require(`@/assets/images/summit/${summit.img_name}`)
                      " />
                  </div>
                  <div class="project-content">
                    <h5 v-if="summit.link" class="summit-link">
                      <a :href="summit.link" class="font-weight-bold" v-html="summit.title"></a>
                    </h5>
                    <h5 v-else class="summit-no-link">
                      <a :href="summit.link" class="summit-no-link" v-html="summit.title"></a>
                    </h5>
                    <p class="workshops" v-html="summit.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 upcoming mx-3">
        <span>For upcoming Workshops & Training follow up on Social Media:</span>
        <br />
        <span><a href="https://www.facebook.com/RWBuild" target="_blank"
            style="text-decoration: none; color: black">Facebook</a>
          &
          <a href="https://twitter.com/RwandaBuild" target="_blank" style="text-decoration: none; color: black">Twitter:
            @RwandaBuild
          </a></span>
      </div>
    </section>
    <!-- past Worshops & Trainings section -->
    <section class="fadeInUp mx-md-4 px-md-4 mx-1 px-1" id="workshop" ref="workshop"></section>
    <!-- Events & Talks -->
    <section class="mx-md-4 px-md-4 mx-sm-1 px-sm-2 fadeInUp pt-4" id="events">
      <div class="container-fluid">
        <div class="widget">
          <h4 class="heading font-bold">Past Events</h4>
          <div style="margin-left:-30px">
            <GalleryPastEventsVue />
          </div>
          <div class="row">
            <div class="mt-4 upcoming mx-3">
              <span>Most events are open to the public with a focus on helping
                build up Kigali developers and entrepreneurs. For upcoming
                events follow up on Social Media:</span>
              <br />
              <span>Facebook: www.facebook.com/RWBuild & Twitter:
                @RwandaBuild</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="gallery site-section fadeInUp pt-1 mx-md-5 mx-3" id="gallery" ref="gallery_slide">
      <div class="mb-3">
        <div class="widget">
          <h4 class="gallery-header section-title font-bold mb-3" style="margin-left: -12px">
            Gallery
          </h4>
          <!-- ======================== New Gallery ============= -->
          <div>
            <!-- <overlappingGallery/> -->
            <galleryFlip />
          </div>
        </div>
      </div>
    </section>
    <!-- Our Graduated project section -->
    <section class="fadeInUp recently-project mx-md-5 px-3 pt-md-2 pt-md-4 pt-sm-1" id="graduated">
      <div class="widget">
        <div class="content">
          <h4 class="mb-4 font-bold our-graduate">
            Our Graduate and Past Team Projects
          </h4>
          <div class="row" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" data-aos-easing="ease-in-out"
            data-aos-mirror="false" data-aos-once="false">
            <div class="d-flex col-lg-4 col-md-6 col-sm-6" v-for="(graduant, index) in graduated" :key="index + 'djdjs'">
              <div class="d-flex project-widget">
                <div class="card">
                  <div class="project-img">
                    <img class="img-responsive" :src="require(`@/assets/images/project/${graduant.img_name}`)
                      " alt="raduated-image" />
                  </div>
                  <div>
                    <h5>
                      <a :href="graduant.link" v-html="graduant.title" target="_blank"></a>
                    </h5>
                    <p class="projects post" v-html="graduant.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- other links -->
    <section class="mx-md-5 mx-3" id="other">
      <div class="container-fluid">
        <div class="widget">
          <div data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" data-aos-easing="ease-in-out"
            data-aos-mirror="false" data-aos-once="false">
            <h4 class="heading font-bold pt-mt-4 pt-sm-2">Press & Other Links</h4>
            <div class="row d-flex flex-wrap">
              <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-md-3 mx-1 my-4">
                    <img src="@/assets/images/rwanda-ambulance.jpeg" class="card-img-fluid image" alt="press-image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://uofuhealth.utah.edu/surgery/globalsurgery/news/2024/05/u-researchers-help-implement-potentially-life-saving-ambulance"
                          target="_blank">
                          U Researchers Help Implement Potentially Life-Saving Ambulance Communication System in Rwanda
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Every year in Rwanda, injury causes 9% of deaths, with 47% of these occurring before patients can
                        reach the hospital.
                        However, death and disability after injury can be substantially reduced if people reach healthcare
                        facilities quickly.
                        Like many low- or middle-income countries (LMIC), Rwanda experiences lengthy delays in getting
                        patients to hospitals as all communications between patients,
                        ambulances, and hospitals are done using multiple phone calls.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-md-3 mx-1 my-4">
                    <img src="@/assets/images/ambulance-rwanda.jpg" class="card-img-fluid image" alt="press-image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.birmingham.ac.uk/news/2024/rwanda-launches-ambulance-communication-system-that-could-save-many-lives"
                          target="_blank">
                          Rwanda launches ambulance communication system that could save many lives
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Ambulance crews in Rwanda’s capital city could save more lives with the launch of
                        a unique electronic system that could speed hospital transfers - reducing the likelihood of people
                        dying unnecessarily from injuries caused by road traffic crashes,
                        accidents, or violence, as well as other emergency condition.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-md-3 mx-1 my-4">
                    <img src="@/assets/images/912.jpeg" class="card-img-fluid image" alt="press-image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.newtimes.co.rw/article/3570/news/technology/local-tech-company-secures-rwf4bn-to-speed-hospital-transfers"
                          target="_blank">
                          New Times Article -912 Rwanda
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Local software firm, Rwanda Build Program (RWBuild) that
                        designed 912Rwanda, an electronic platform that uses
                        data to improve pre-hospital transport of injured
                        people, says it has secured £3 million (approximately
                        Rwf4bn) funding from the UK’s National Institute for
                        Health and Care Research (NIHR).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-3 my-4">
                    <img src="@/assets/images/mic.jpg" class="card-img-fluid image" alt="press-image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.newtimes.co.rw/article/145326/News/technology-start-ups-the-potential-waiting-to-happen"
                          target="_blank">
                          New Times Article- RWBuild Tech Launch
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Local tech start-up sub-sector has been earmarked as an
                        opportunity for the country to solve unemployment
                        challenges, especially among the youth, as well as drive
                        the entrepreneurial culture.
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
    <footer_comp />
  </div>
</template>
<script>
import "@/assets/css/imgal.min.css";
import { page } from "vue-analytics";
import { setTimeout } from "timers";
import footer_comp from "@/components/footer.vue";
import galleryFlip from "@/components/gallery-flip.vue"
import GalleryPastEventsVue from '../components/GalleryPastEvents.vue';

export default {
  metaInfo: {
    title: "Rwanda Build Program (RWBuild),is a Tech Hub & Startup Studio in Kigali, Rwanda",
    meta: [
      {
        property: "og:description",
        content:
          "Rwanda Build Program (RWBuild),is a Tech Hub & Startup Studio in Kigali, Rwanda",
      },
      
      {
        property: "twitter:title",
        content: "Rwanda Build Program (RWBuild)",
      },
     
      {
        property: "twitter:url",
        content: "https://rwandabuildprogram.com/",
      },
       {
        property: "twitter:url",
        content: "https://rwandabuildprogram.com/",
      },
      {
        property: "twitter:description",
        content:
          "Rwanda Build Program (RWBuild),is a Tech Hub & Startup Studio in Kigali, Rwanda",
      },
    ],
  },
  name: "home",
  components: {
    footer_comp,
    galleryFlip,
    GalleryPastEventsVue
  },
  data() {
    return {
      renderSplash: false,
      isVision: false,
      isWorkshop: false,
      isSummit: false,
      showGallery: false,
      isProject: false,
      bannerActiveItem: null,
      pitch_bannerActiveItem: null,
      isBannerPlaying: false,
      Links: [
        { url: "http://www.newtimes.co.rw/section/read/222237", logo: null },
        { url: "https://www.facebook.com/RWBuild", logo: "facebook" },
        { url: "https://www.twitter.com/RwandaBuild", logo: "tweeter" },
        { url: "https://www.twitter.com/commonworld", logo: "tweeter" },
      ],
      img_number: 20,

      projects: [
        {
          img_name: "Murugo.png",
          title: "Murugo",
          content:
            " The MurugoCloud is set to become the primary digital ecosystem  for Africa. It will serve as your digital home, starting in Kigali, Rwanda, with single  sign-on access, extensive location services, notifications, user and contact  management, user verification, syncing, software development tools, and many  other features.",
          // "The Murugo Cloud will be the essential digital ecosystem for Africa. It will be your digital home. Designed and developed in Kigali. #RwandaCode100%",
          link: "https://murugo.cloud/",
        },
        {
          img_name: "Guhemba.jpg",
          title: "Guhemba",
          content:
            "Guhemba is a platform & super-app supporting mobile and e commerce payments, person-to-person transfers, and a suite of other powerful  services, all through unified digital Wallets (personal & merchant). Guhemba is  designed to fit the future culture of transferring funds and making payments across  Africa. A Rwandan company proudly develops the product, which is being tested in  Kigali with outstanding results",
          link: "https://guhemba.rw/",
          tweeterlink: "https://twitter.com/guhemba?lang=en",
        },
        {
          img_name: "912RW.png",
          title: "912 Rwanda",
          content:
            "912Rwanda, an innovative online dispatch and ambulance  solution that is revolutionizing emergency medical services in Kigali, Rwanda. The  primary aim of 912Rwanda Phase 1 was to reduce the overall travel time of  emergency ambulances in Kigali, and to launch the foundational dispatch and  ambulance software platform. The platform provides a unified control system for  pre-hospital operations, making it a highly efficient solution. The project was  planned and designed by RWBuild in collaboration with the Ministry of Health and  international global health partners. Phase 2 will introduce facility decision logic and  enhanced patient data collection.",
          link: "https://912rwanda.com/",
        },
        {
          img_name: "Bash.jpg",
          title: "Events-Bash",
          content:
            'The popular website Events Bash is the number one online  platform in Kigali for finding all the latest happenings and events in Kigali. "Events  Bash" is a well-known site where event planners can list their events and even sell  tickets. It is an excellent service for hosts who need to keep track of their ticket  sales and manage the scanning of tickets at the door.',
          link: "https://eventsbash.rw/",
        },
        {
          img_name: "helloKigali.jpg",
          title: "Hello Kigali",
          content:
            "Hello Kigali is a city discovery app (and so much more). This  app was built by local street teams venturing out to find the best spots and their  exact locations. It could be the most trusted app for where places in Rwanda are  exactly located. Currently, Hello Kigali has over 3,000 sites like restaurants, cafes,  nightclubs, hotels, embassy, shopping, medical, tourism, wifi locations, and other  desired locations. There is also a Taxi/Moto cost estimator and built-in directions.",
          link: "https://hellokigali.com",
          fblink: "https://www.facebook.com/HelloKigali/",
          tweeterlink: "https://twitter.com/hellokigali?lang=en",
        },
        {
          img_name: "candidate.png",
          title: "Candidate",
          content:
            "The Candidate platform enables anyone to find open positions  and job opportunities with startups, businesses, and other organizations. The  platform uses advanced matching technologies to help candidates find the right  jobs, opportunities, and programs that suit their skills and experience. Notably, this  platform has a unique feature that helps startup founders connect with investors  and stakeholders.",
          link: "https://candidate.rw/",
        },
        // {
        //   img_name: "",
        //   title: "",
        //   content:"",
        //   // link: "#",
        // },
        {
          img_name: "chat_boot.gif",
          title: "Murugo Chat",
          content:
            "An exciting new Murugo Service to be launched in the future.",
          // link: "#",
        },
        {
          img_name: "KQ.png",
          title: "Kigali Quest & Domination",
          content: "Social location-based Games to be launched in the future.",
          // link: "#",
        },
      ],
      graduated: [
        {
          img_name: "Ifeza.jpg",
          title: "iFeza Florists",
          content:
            "iFeza sells flowers and more from its e-commerce site. They support both local and internal sales to deliver free anywhere in Kigali. They are a profitable boutique company. A future update is planned to be built in RWBuild using our development process and staff.",
          link: "https://ifeza.rw/",
        },
        {
          img_name: "brand.svg",
          title: "RWBuild Covid Tracker",
          content:
            "The Covid Tracker has up-to-date data from the Ministry of Health charted in graphs and other local information for the Pandemic.",
          link: "https://covid.rwandabuildprogram.com/",
        },
      ],
      talks: [
        {
          img_name: "startup_founders_talk_brang_logo.png",
          title: "RWBuild Startup Founders Talk",
          content:
            "Follow us on Twitter for updated current event schedules, and check  EventsBash.rw for a complete events listing of what is happening in Kigali) ",
          link: "https://twitter.com/Rickard/status/1659524923193270277",
        },
        {
          img_name: "startup_demo_day_logo_720.png",
          title: "RWBuild Startup Demo",
          content:
            "Build this for updates to come (as events added like Mobile Summit 2024 and  mHealth Summit 2024) ",
          link: "https://twitter.com/RwandaBuild",
        },
      ],
      summits: [
        {
          img_name: "Speraker-College 1.jpg",
          title: "RWBuild Code Summit Jan 2022 ",
          content:
            "A code summit for  coders, by coders, and all about the code. We invited the most significant  software developers and companies to share and grow their software skills and  knowledge.",
          link: "/codesummit",
        },
        {
          img_name: "RWBuild Summit Advertising Graphic.png",
          title: "RWBuild Workshops Feb 2022",
          content:
            "The Jan Candidate Workshop is an intense 3 weeks up-skilling backend and frontend developers, The Candidate program is also job placement program for also leveling up developers by adding experiences in real projects.",
        },
        {
          img_name: "RWBuild Summit Development Process.png",
          title: "The Software Development Process",
          content:
            "A full-day workshop ran by two master project planners in software development. This workshop would help any team understand why they need a proper development process and project management.",
        },
        {
          img_name: "RWBuild Summit Killer Pitch.png",
          title: "How to Give a Killer Presentation",
          content:
            "Building and then performing a great presentation is not easy. This 3-hour workshop covers both best practices for building a presentation and how to adequately perform that great presentation. There is an extra focus on 'the pitch' for startups.",
        },
        {
          img_name: "Workshop Graphic - PHP Swift.png",
          title: "PHP & Swift Software Development",
          content:
            "Two three-week workshops. The goal was on leveling up developer knowledge and skill. A one to a three-hour training session with the rest of the day focused on completing the lessons. The focus: best practices, codding behaviors, and foundational approaches.",
        },
      ],
      events: [
        {
          id: "1",
          img_name: "talk_5.png",
          title: "Software Launch Parties",
          description: "Software Launch Events.",
          date: "2019 June 01",
        },
        {
          id: "2",
          img_name: "talk_1.png",
          title: "Pitch Night & Mentoring Feedback",
          description:
            "	Come and pitch your projects and services - get feedback on your pitch",
          date: "2019 June 02",
        },
        {
          id: "3",
          img_name: "talk_3.png",
          title: "30 Minutes of Time Management",
          description: "A quick talk on time management and productivity.",
          date: "2019 June 03",
        },
        {
          id: "4",
          img_name: "talk_4.png",
          title: "30 Minutes of Productivity",
          description:
            "A quick talk about using tech to communicate and be more productive.",
          date: "2019 June 04",
        },
        {
          id: "5",
          img_name: "Talk Graphic 6.png",
          title: "Noodles and Code",
          description:
            "Lunch and code (often Chinese noodles). Collaborate and share best practices and code approaches.",
          date: "2019 June 05",
        },
        {
          id: "6",
          img_name: "Talk Graphic 11 - Plan Writing.png",
          title: "Project Planning and Proper Plan Writing",
          description:
            "A simple way of establishing a workable scope and building a roadmap that you can execute on. If you don't plan well, you won't build well.",
          date: "2019 June 06",
        },
        {
          id: "7",
          img_name: "talk_7.png",
          title: "How to build and give Killer Presentations",
          description: "Learn the secrets of giving a 'killer pitch.",
          date: "2019 June 07",
        },
        {
          id: "8",
          img_name: "Talk Graphic 10 - COC.png",
          title: "Culture of Communication - Business communications",
          description:
            "A talk about technology, collaboration and better communication.",
          date: "2019 June 08",
        },
        {
          id: "9",
          img_name: "Talk Graphic 2 - Advice from Techpreneur.png",
          title: "Advice from a Techpreneur",
          description: "What makes and breaks a startup.",
          date: "2019 June 09",
        },
        {
          id: "10",
          img_name: "Talk Graphic 9.png",
          title: "WWDC Events",
          description:
            "World Wide Developer Conference events and live streams.",
          date: "2020 June 22",
        },
        {
          id: "11",
          img_name: "Talk Graphic 11 - Solo Breakfast.png",
          title: "Movie Night (or Day)",
          description:
            "	Movies and other entertainment bundled with food, drinks, and fun..",
          date: "2019 June 09",
        },
        {
          id: "12",
          img_name: "party.png",
          title: "RWBuild Member Appreciation",
          description:
            "New Software Features Launch +Food and Drink+ Entertainment",
          date: "2023 June 07",
        },
      ],
      modal_img_index: 1,
      modal_status: false,
      activeSlider: {
        image: "",
        title: "",
        description: "",
        date: "",
      },
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {

  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.loadData);
  },
  methods: {
    // this will get a certain section position
    isSection(elem) {
      let coords = elem.getBoundingClientRect();
      let windowHeight = document.documentElement.clientHeight;
      // show the top of the element
      let topElem = coords.top > 0 && coords.top < windowHeight;
      // show the bottom element
      let bottomElem = coords.bottom < windowHeight && coords.bottom > 0;
      return topElem || bottomElem;
    },
    // this willu load the sections on user scroll..
    loadData() {
      let splashSection = this.$refs["splash_ref"];
      let gallerySection = this.$refs["gallery_slide"];
      let workshopSection = this.$refs["workshop"];
      // let projectSection = this.$refs["project_ref"];
      let visionSection = this.$refs["vision_ref"];
      let summitSection = this.$refs["summit_ref"];
      if (this.isSection(splashSection)) {
        this.renderSplash = true;
      }
      if (this.isSection(visionSection)) {
        this.isVision = true;
      }
      // if (this.isSection(projectSection)) {
      //   this.isProject = true;
      // }
      if (this.isSection(workshopSection)) {
        this.isWorkshop = true;
      }
      if (this.isSection(summitSection)) {
        this.isSummit = true;
      }
      if (this.isSection(gallerySection)) {
        this.showGallery = true;
      }
    },

    callback() {
      //read the API docs to see which slider events are available
    },
    /**
     * @desc Allow to slide into banner images
     */
    playBanner() {
      let vm = this;
      vm.isBannerPlaying = true;
      setTimeout(() => {
        vm.bannerSlide();
      }, 800);
    },
    /**
     * @desc Allow to slide pitch banner images
     */
    banner_Pitch_Slide() {
      let vm = this;
      let slide_items = document.querySelectorAll(
        ".banner-slide_pitch .slide-item"
      );
      // eslint-disable-next-line no-unused-vars
      let bannerSlider = document.querySelector(".banner-slide_pitch");
      let items_len = slide_items.length;

      if (items_len > 1) {
        if (vm.pitch_bannerActiveItem == null) {
          slide_items[0].classList.add("active");
          vm.pitch_bannerActiveItem = 0;
          vm.banner_Pitch_Slide();
        } else {
          setTimeout(() => {
            slide_items[vm.pitch_bannerActiveItem].classList.remove("active");
            let next = vm.pitch_bannerActiveItem + 1;
            if (next < items_len) {
              slide_items[next].classList.add("active");
              vm.pitch_bannerActiveItem = next;
              vm.banner_Pitch_Slide();
            } else {
              vm.pitch_bannerActiveItem = null;
              vm.banner_Pitch_Slide();
            }
          }, 25000);
        }
      }
    },
    /**
     * @desc Allow to slide banner images
     */
    bannerSlide() {
      let vm = this;
      let slide_items = document.querySelectorAll(".banner-slide .slide-item");
      let bannerSlider = document.querySelector(".banner-slide");
      let items_len = slide_items.length;
      let isPaused = false; // Initialize a variable to track if the animation is paused.

      // Function to pause the animation
      function pauseAnimation() {
        isPaused = true;
      }

      // Function to resume the animation
      function resumeAnimation() {
        isPaused = false;
        // Restart the animation
        vm.bannerSlide();
      }

      if (items_len > 1) {
        if (vm.bannerActiveItem == null && !isPaused) {
          if (!isPaused) {
            slide_items[0].classList.add("active");
            vm.bannerActiveItem = 0;
            vm.bannerSlide();
          }
        } else {
          setTimeout(() => {
            if (!isPaused) {
              slide_items[vm.bannerActiveItem].classList.remove("active");
              let next = vm.bannerActiveItem + 1;
              if (next < items_len) {
                slide_items[next].classList.add("active");
                vm.bannerActiveItem = next;
                vm.bannerSlide();
              } else {
                vm.bannerActiveItem = null;
                vm.bannerSlide();
              }
            }
          }, 25000);
        }

        // Add event listeners to pause and resume animation
        bannerSlider.addEventListener("mouseover", pauseAnimation);
        bannerSlider.addEventListener("mouseout", resumeAnimation);
      }
    },
    /**
     * @desc All to track page for google anaytic
     */
    track() {
      page("/");
    },
    /**
     * @desc Allow to show modal image preview
     */
    showModalImage(index) {
      this.modal_status = true;
      document.body.style.overflow = "hidden";
      this.modal_img_index = index;
    },
    /**
     * @desc Allow to clode modal image preview
     */
    closeModal() {
      document.body.style.overflow = "";
      this.modal_status = false;
    },
    /**
     * @desc Allow to slide image rigth
     */
    slideModalRight() {
      if (this.modal_img_index <= this.img_number) {
        this.modal_img_index = this.modal_img_index + 1;
      } else {
        this.modal_img_index = 1;
      }
    },
    /**
     * @desc allow to slide image left
     */
    slideModalLeft() {
      if (
        this.modal_img_index <= this.img_number &&
        this.modal_img_index >= 0
      ) {
        this.modal_img_index = this.modal_img_index - 1;
      } else {
        this.modal_img_index = this.img_number;
      }
    },
    goToAboutUs() {
      this.$router.push("/about-us");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$assets: "~@/assets/";

.event-link {
  color: rgba(245, 245, 245, 0.872);
  cursor: pointer;
}

.event-link:hover {
  color: rgba(245, 245, 245, 0.872) !important;
  cursor: pointer;
}

.str1 {
  stroke: #bdbfc1;
  stroke-width: 6.94488;
}

.str0 {
  stroke: #96938e;
  stroke-width: 6.94488;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.fil1 {
  fill: none;
}

.fil0 {
  fill: none;
  fill-rule: nonzero;
}

.banner_image {
  padding-top: 20px;

  img {
    width: 100%;
  }

  .play_btn {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #0000008a;
    font-size: 30px;
    position: absolute;
    left: 38px;
    top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #3c3c3c;

    span {
      color: #fff;
      margin-left: 9px;
    }
  }

  .play_btn::before,
  .play_btn::after {
    content: "";
    display: block;
    position: absolute;
    border: 50%;
    border: 3px solid #797979;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 50%;
    animation: animate 1s linear infinite;
    opacity: 0;
  }

  .play_btn:after {
    animation-delay: 0.5s;
  }

  @keyframes animate {
    0% {
      transform: scale(0.5);
      opacity: 0;
      border: 3px solid #797979;
    }

    50% {
      opacity: 1;
      border: 2px solid #b7b4b4;
    }

    100% {
      transform: scale(1.2);
      opacity: 0;
      border: 1px solid #ccc;
    }
  }
}

.more-info {
  text-transform: capitalize;
  font-size: 15px;
}

.event-image-widget {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    border-radius: 8px;
  }
}

.swiper-container {
  padding-top: 15px;
}

.swiper-slide.swiper-slide-active {
  img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
  }
}

.event-carousel {
  .headline {
    margin-bottom: 8px;
  }
}

.event-title {
  line-height: 14px;
  margin-top: 4px;
  color: #696969;
  font-size: 22px;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;

  a {
    text-decoration: none;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
}

.event-description,
.event-date {
  color: gray;
  font-size: 16px;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
}

.event-date {
  margin-top: -4px;
}

.event {
  width: 175px;
  /*Adding margins on left and right as with value auto allows the box to center itself in
        the parent(swiper-slide) div*/
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 600px) {
    width: 100%;
  }

  .event-image {
    height: 75px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .event-footer {
    padding-bottom: 8px;
  }
}

.summit-no-link {
  text-decoration: none;
  color: black;
  font-weight: 900;
  font-family: "Proxima Nova";
}

.summit-no-link:hover {
  text-decoration: none;
  color: black !important;
  font-weight: bold;
}

.summit-link {
  text-decoration: underline;
  color: #007bff !important;
}

.summit-link,
a:hover {
  text-decoration: underline;
  color: #007bff !important;
}

.image-card {
  margin: auto;
  vertical-align: middle;
}

.image {
  max-height: 140px;
}

.paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 13px;
}

.upcoming {
  font-size: 16px;
}

.current {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.trainings {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show post*/
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.post {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.headin {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 16px;
  cursor: pointer;
  color: #007bff !important;
}

.headin a:hover {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: #007bff !important;
}

.article {
  font-size: 16px;
  font-family: "Proxima Nova";
}

.mission {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  -webkit-line-clamp: 10;
  color: rgba(245, 245, 245, 0.872) !important;
  line-height: 1.35rem;
}

.workshops,
.projects {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  // font-weight: 900;
  font-family: "Proxima Nova";
  margin: 10px 0px;
  line-height: 1.7rem;
}

.display {
  font-size: 22px;
}

@media (max-width: 1024px) {
  .responsive {
    max-width: 160px;
  }
}

@media (min-width: 900px) {
  .responsive {
    max-width: 160px;
    max-height: 240px;
  }
}

@media (min-width: 800px) {
  .responsive {
    max-width: 160px;
    max-height: 314px;
  }
}

@media (min-width: 600px) {
  .responsive {
    max-width: 160px;
    max-height: 380px;
  }
}

@media (min-width: 450px) {
  .responsive {
    max-width: 130px;
    max-height: 380px;
  }
}

@media only screen and (max-width: 1000px) {
  .gallery {
    // margin-left: 12px !important;
    margin-bottom: 100px;
  }

  .current-project {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .gallery {
    margin-left: 12px;
  }
}

.primary {
  color: rgba(245, 245, 245, 0.872);
  background-color: #007bff;
  border-color: #007bff;
}

.primary:hover {
  background-color: #007bff;
}

.secondary {
  border-radius: 11px;
  font-size: 24px;
}

.more {
  font-size: 19px !important;
}

.title {
  margin-bottom: 20px;
}

.slider {
  font-size: 18px !important;
}

.heading {
  font-size: 25px !important;
  margin-bottom: 20px !important;
}

.dark {
  background-color: rgb(34, 33, 33);
  overflow-x: hidden;
}

.event_content {
  height: 350px;
}

$assets: "~@/assets/";

@font-face {
  font-family: "Proxima Nova";
  src: url($assets + "font/ProximaNova-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@import "~@/assets/timeline/style.css";

.cd-timeline {
  background-color: transparent !important;

  .cd-timeline__content {
    border: 1px solid #ddd !important;
    box-shadow: none;
  }

  .container {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 100%;
    }
  }

  .text-component p {
    color: rgb(16, 16, 16) !important;
    font-size: 17px !important;
    font-family: "Proxima Nova";
  }

  .cd-timeline__date {
    font-family: "Proxima Nova";
  }
}
</style>
