
<template>
  <swiper
    :spaceBetween="30"
    :slidesPerView="3"
    :centeredSlides="true"
    :breakpoints="breakpoints"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :loopFillGroupWithBlank="false"
    :loop="true"
    :navigation="false"
    class="mySwiper"
  >
    <swiper-slide v-for="(event, index) in events" :key="index">
      <div class="event">
        <div class="event-image col-12">
          <img
            :id="event.id"
            :data-event-id="event.id"
            :src="require(`@/assets/images/912/${event.img_name}`)"
            style="height: 100%; 
             width: 100%"
            class="img-fluid"
          />
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
// import './style.css';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        900: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        300: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
      events: [
        {
          id: "3",
          img_name: "912RW Pic Exports 3.png",
        },
        {
          id: "4",
          img_name: "912Rwanda-Pic.3-Feedback-Nov.28.2020.png",
        },
        {
          id: "5",
          img_name: "912Rwanda-Pic.4-Feedback-Nov.19.2020.png",
        },
        {
          id: "6",
          img_name: "912Rwanda-Pic.8-Feedback-Oct.10.png",
        },
        {
          id: "7",
          img_name: "912Rwanda-Pic.9-Feedback-Nov.19.2020.png",
        },
        {
          id: "8",
          img_name: "912RW Pic Exports community pin 2023.png",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style>

</style>